<template>
  <a-modal v-model="isShow" :title="(this.form.id ? '编辑' : '新增') + '字典项'" @cancel="close" @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :model="form" :rules="formRules" class="al-form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
      <a-form-model-item label="字典项名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入，名称不能重复" />
      </a-form-model-item>
      <a-form-model-item label="字典项编码" prop="code">
        <a-input :disabled="form.id!==''" v-model="form.code" placeholder="请输入，编码不能够重复" />
      </a-form-model-item>
      <a-form-model-item label="字典项值" prop="value">
        <a-input v-model="form.value" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort">
        <a-input v-model="form.sort" placeholder="请输入" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  inject: ['appRefs'],
  props: {
    dict: {
      type: Object,
      default: _ => {
        return {}
      }
    }
  },
  data () {
    const validPositiveInteger = (rule, value, callback) => {
      if (value && !(/(^[1-9]\d*$)/.test(value))) {
        return callback(new Error('请输入正整数'))
      }
      callback()
    }
    return {
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      form: { // 表单
        id: '', // ID
        code: '', // 编码
        name: '', // 名称
        value: '', // 值
        sort: '' // 排序
      },
      formRules: { // 表单规则
        code: [{
          required: true, message: '请输入编码', trigger: 'change'
        }],
        name: [{
          required: true, message: '请输入名称', trigger: 'change'
        }],
        value: [{
          required: true, message: '请输入值', trigger: 'change'
        }],
        sort: [{
          validator: validPositiveInteger, trigger: 'change'
        }]
      }
    }
  },
  methods: {
    // 关闭弹窗
    close () {
      this.$refs.form.resetFields()
      this.form = { // 表单
        id: '', // ID
        code: '', // 编码
        name: '', // 名称
        value: '', // 值
        sort: '' // 排序
      }
    },
    async determine () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          params.dictId = this.dict.id
          let api = 'addDictItem'
          if (this.form.id) {
            api = 'modifyDictItem'
          } else {
            delete params.id
          }
          await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
              this.form = {}
            }
          })
          this.$store.dispatch('showToast', { message: (this.form.id ? '编辑' : '新增') + '字典成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>
