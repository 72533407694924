<template>
  <div>
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <a-button type="primary" @click="$refs.dialog.isShow = true">新建</a-button>
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }" :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item>
        <a-input v-model="form.name" placeholder="名称" />
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.code" placeholder="编号" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>

    <a-table :data-source="list" :rowKey="record => record.id" :columns="columns" :pagination="pagination">
      <span slot="action" slot-scope="dict" v-if="currentTenantId === dict.tenantId">
        <a @click="$refs.dialog.form = $utils.clone(dict);$refs.dialog.isShow = true">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm
            title="你确定要删除这行内容吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="confirm(dict)"
            @cancel="cancel"
          >
          <a href="#"><a-spin size="small" v-if="dict.isLoading"/>删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <dialog-save ref="dialog" :dict="dict" @save="reloadList"></dialog-save>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import DialogSave from './addOrEditDictItem'

export default {
  components: {
    Breadcrumb,
    DialogSave
  },
  data () {
    return {
      currentTenantId: this.$store.state.user.currentTenantId,
      breadList: [
        {
          name: '字典管理',
          url: '/dict/list'
        },
        {
          name: '字典内容管理',
          url: ''
        }
      ],
      dict: { // 字典
        id: ''
      },
      isQueryLoading: false,
      form: {
        name: '',
        code: ''
      },
      columns: [
        { title: '字典项名称', dataIndex: 'name', key: 'name', ellipsis: true },
        { title: '字典项编码', dataIndex: 'code', key: 'code', ellipsis: true },
        { title: '字典项值', dataIndex: 'value', key: 'value', width: 200, ellipsis: false },
        { title: '排序', dataIndex: 'sort', key: 'sort', width: 100, ellipsis: true },
        { title: '更新时间', dataIndex: 'gmtModified', key: 'gmtModified', width: 200, ellipsis: true },
        { title: '操作', scopedSlots: { customRender: 'action' }, key: 'operation', fixed: 'right', width: 150, ellipsis: true }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.$store.commit('SET_CURR_ROUTE', {
      name: 'dictList',
      path: '/dict/list'
    })
    this.dict = { id: this.$route.params.id }
    this.queryDict()
    this.reloadList()
  },
  methods: {
    async queryDict () {
      const dict = await this.$store.dispatch('http', {
        api: 'queryDict',
        query: {
          id: this.dict.id
        },
        complete: () => {
          this.isTableLoading = false
        }
      })
      this.dict = dict
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: '',
        code: ''
      }
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {}) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {}) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        dictId: this.dict.id
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'queryDictItems',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      records.forEach(item => {
        item.isLoading = false
      })
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    },
    cancel () {},
    // 删除字典项
    confirm (dict) {
      this.del(dict)
    },
    async del (dict) {
      dict.isLoading = true
      const res = await this.$store.dispatch('http', {
        api: 'deleteDictItem',
        query: {
          id: dict.id
        }
      })
      if (res) {
        dict.isLoading = false
        this.$store.dispatch('showToast', { message: '删除成功~' })
        this.queryList()
      }
    }
  }
}
</script>
